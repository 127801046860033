import {Link} from "gatsby"
import React from "react"
// import logo from "../../static/images/logo1.png"
import logo from "../../static/images/logo-new.png"
// import PropTypes from "prop-types"
import iconTwitter from "../../static/images/icon-twitter.svg"
// import iconLinkedin from "../../static/images/icon-linkedin.svg"

const Footer = () => (
    <footer>
        <div className={""} style={{paddingLeft: "5%", paddingRight: "5%"}}>
            <div className={"row"}>
                {/* <div className={"col-2"}>
                    <div className={"widget__item"}>
                        <div className={"logo"}>
                            <Link to="/" title={"ManagersApp"}>
                                <img alt={"Logo"} src={logo} />
                            </Link>
                        
                        </div>
                        <div className={"about"}>
                            <p>A software that was created with the goal of helping Engineering Managers</p>
                        </div>
                    </div>
                </div> */}
                <div className={"col-3"}>
                    <div className={"widget__item"}>
                        <div className={"links"}>
                            <Link to="/" title={"ManagersApp"}>
                                <img alt={"Logo"} src={logo} width="40%" height="40%" style={{marginTop: "-20px"}}/>
                            </Link>
                            <ul style={{marginTop: "-10px"}}>
                                {/* <li><Link to="/about" title={"About Us"}>About</Link></li> */}
                                <li><Link to="/faqs" title={"FAQs"}>FAQs</Link></li>
                                <li><a href={"/blog"}>Blog</a></li>
                                <li><Link to="/pricing" title={"Pricing"}>Pricing</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"col-3"}>
                    <div className={"widget__item"}>
                        <div className={"links"}>
                            <h4>CONTACT US</h4>
                            <ul>
                                <li>
                                    <Link href={"mailto:hello@managers.app"}>hello@managers.app</Link>
                                </li>
                                <li><Link to="/support" title={"Need support?"}>Support</Link></li>
                                <li>
                                    <a href="https://www.linkedin.com/company/managersapp" target={"_blank"} rel="noreferrer" title={"LinkedIn"}>LinkedIn</a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/Managers_App" target={"_blank"} rel="noreferrer" title={"Twitter"}><img alt={"Twitter"} src={iconTwitter} /></a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"col-2"}>
                    <div className={"widget__item"}>
                        <div className={"links"}>
                            <h4>LEGAL STUFF</h4>
                            <ul>
                                <li><Link to="/privacy" title={"Privacy Policy"}>Privacy</Link></li>
                                <li><Link to="/terms" title={"Terms Of Use"}>Terms Of Use</Link></li>
                                <li><Link to="/security" title={"Security"}>Security</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"col-4"}>
                    <div className={"widget__item"}>
                        <div className={"links"}>
                            <h4>Latest Posts</h4>
                            <ul>
                                <li><Link to="/lets-build-a-perfect-house-together/" title={"Let’s Build a Perfect House Togethe"}>Let's Build a Perfect House Together</Link></li>
                                <li><Link to="/code-quality-and-efficiency-in-product-centric-organizations/" title={"Ensuring Code Quality and Efficiency in Product-Centric Organizations"}>Ensuring Code Quality and Efficiency in Product-Centric Orgs</Link></li>
                                <li><Link to="/on-importance-of-ownership" title={"On the Importance Of Ownership"}>On the Importance Of Ownership</Link></li>
                                <li><Link to="/driving-cross-functional-collaborations-to-success" title={"Driving Cross Functional Collaborations to Success"}>Cross Functional Projects</Link></li>
                                <li><Link to="/growing-individual-contributors-as-leaders" title={"Growing Individual Contributors As Leaders"}>Growing Individual Contributors As Leaders</Link></li>
                                <li><Link to="/skip-level-meetings" title={"Skip Level Meetings"}>Skip Level Meetings</Link></li>
                                <li><Link to="/guide-to-engineering-operations" title={"Guide to Engineering Operations"}>Guide to Engineering Operations</Link></li>
                                <li><Link to="/performance-management" title={"Performance Management"}>Continuous Performance Management</Link></li>
                                <li><Link to="/managing-360-degree-feedback" title={"Managing 360 Degree Feedback"}>Managing 360 Degree Feedback</Link></li>
                                <li><Link to="/effective-one-on-one-meetings" title={"Effective One On One Meetings"}>Effective One On One Meetings</Link></li>
                                <li><Link to="/accountability-versus-responsibility" title={"Accountability Versus Responsibility"}>Accountability Versus Responsibility</Link></li>
                                <li><Link to="/engineering-management-responsibilities" title={"Engineering Management Responsibilities"}>Engineering Management Responsibilities</Link></li>
                                <li><Link to="/personal-growth-plan" title={"Personal Growth Plan"}>Personal Growth Plans</Link></li>
                                <li><Link to="/team-check-in-meetings/" title={"Team Check-In Meetings"}>Team Check-In Meetings</Link></li>
                                <li><Link to="/questions-to-ask-when-you-are-a-new-manager" title={"Questions To Ask When When You Are A New Manager"}>When You Are A New Manager</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className={"col-2"}>
                    <div className={"widget__item"}>
                        <div className={"social"}>
                            <a href="https://www.linkedin.com/company/managersapp" target={"_blank"} rel="noreferrer" title={"LinkedIn"}><img alt={"LinkedIn"} src={iconLinkedin} /></a>
                            <a href="https://twitter.com/Managers_App" target={"_blank"} rel="noreferrer" title={"Twitter"}><img alt={"Twitter"} src={iconTwitter} /></a>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className={"copyright"}>
                <p>Copyright {new Date().getFullYear()}, {` `} <a href="https://managers.app" title={"ManagersApp"}>Managers App</a>. All rights reserved.</p>
            </div>
        </div>
    </footer>
)

export default Footer
