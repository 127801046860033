import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ToolbarMenu from "../components/ToolbarMenu/ToolbarMenu";
import { MenuItem } from "@material-ui/core"
import logo from "../../static/images/logo-new.png"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        width: "95%",
        marginRight: "auto",
        marginLeft: "auto",
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
        color: "black"
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    header: {
        position: "relative",
        zIndex: "59"
    },
    headerMain: {
        // padding: "40px 0",
        paddingRight: "40px",
        background: "0 0",
        boxShadow: "none",
    },
    logoText: {
        fontSize: "2rem",
        letterSpacing: "-0.5px",
        fontWeight: "600",
        lineHeight: "1",
        fontFamily: "'Work Sans',sans-serif",
        verticalAlign: "middle",
        padding: "5px"
    },
    color1: {
        color: "#0f4c75"
    },
    color2: {
        color: "#0f4c75"
    },
    navLinks: {
        display: 'flex',
        flexDirection: "row",
        marginBottom: "0px"
    },
    navLink: {
        fontSize: "2.3rem",
        // fontWeight: "bold",
        textDecoration: "none",
        color: "black",
        padding: "20px",
        textTransform: 'none'
    }
}));

const Header = ({ siteTitle }) => {
    const classes = useStyles();
    return (
        <header>
            <div className={"container"}>
                <div className={"top-menu"}>
                    <div className={"logo"}>
                        <Link to="/" title={"ManagersApp"}>
                            <img alt={"Logo"} src={logo} />
                        </Link>
                    </div>
                    <div className="right-menu">
                        <ToolbarMenu
                            key="toolbar_menu"
                            render={collapsed => {
                                return collapsed
                                    ? [
                                        <MenuItem key="home" autoclose={true}>
                                            <Link
                                                variant="button"
                                                color="textPrimary"
                                                href="/"
                                            className={classes.navLink}
                                            >
                                                Home
                                        </Link>
                                        </MenuItem>,
                                        <MenuItem key="blog" autoclose={true}>
                                            <Link
                                                variant="button"
                                                color="textPrimary"
                                                href="/blog"
                                            className={classes.navLink}
                                            >
                                                Blog
                                        </Link>
                                        </MenuItem>,
                                        // <MenuItem key="random" autoclose={true}>
                                        //     <Link
                                        //         variant="button"
                                        //         color="textPrimary"
                                        //         href="/random"
                                        //         className={classes.navLink}
                                        //     >
                                        //         Random Questions
                                        // </Link>
                                        // </MenuItem>,
                                        <MenuItem key="newsletter" autoclose={true}>
                                            <Link
                                                variant="button"
                                                color="textPrimary"
                                                href="/newsletter"
                                            className={classes.navLink}
                                            >
                                                Newsletter
                                        </Link>
                                        </MenuItem>,
                                        <MenuItem key="faqs" autoclose={true}>
                                            <Link
                                                variant="button"
                                                color="textPrimary"
                                                href="/faqs"
                                            className={classes.navLink}
                                            >
                                                FAQs
                                        </Link>
                                        </MenuItem>,
                                        <MenuItem key="pricing" autoclose={true}>
                                            <Link
                                                variant="button"
                                                color="textPrimary"
                                                href="/pricing"
                                            className={classes.navLink}
                                            >
                                                Pricing
                                        </Link>
                                        </MenuItem>,
                                        <MenuItem key="signin" autoclose={true}>
                                        <span className={"get-started"} style={{fontSize: "2.5rem"}}>
                                            <a href={"https://app.managers.app/signin"}>Sign Up / Sign In</a>
                                        </span>
                                        </MenuItem>
                                    ]
                                    : [
                                        <Link
                                            key="home"
                                            variant="button"
                                            color="textPrimary"
                                            href="/"
                                            className={classes.navLink}
                                            style={{ textTransform: 'none' }}
                                        >
                                            Home
                                    </Link>,
                                        <Link
                                            key="blog"
                                            variant="button"
                                            color="textPrimary"
                                            href="/blog"
                                            className={classes.navLink}
                                        >
                                            Blog
                                     </Link>,
                                    //     <Link
                                    //         key="random"
                                    //         variant="button"
                                    //         color="textPrimary"
                                    //         href="/random"
                                    //         className={classes.navLink}
                                    //     >
                                    //         Random
                                    //  </Link>,
                                        <Link
                                            key="newsletter"
                                            variant="button"
                                            color="textPrimary"
                                            href="/newsletter"
                                            className={classes.navLink}
                                        >
                                            Newsletter
                                     </Link>,
                                        <Link
                                            key="faqs"
                                            variant="button"
                                            color="textPrimary"
                                            href="/faqs"
                                        className={classes.navLink}
                                        >FAQs
                                     </Link>,
                                        <Link
                                            key="pricing"
                                            variant="button"
                                            color="textPrimary"
                                            href="/pricing"
                                        className={classes.navLink}
                                        >Pricing
                                    </Link>,
                                    <span className={"get-started"} style={{fontSize: "2.5rem"}}>
                                        <a href={"https://app.managers.app/signin"}>Sign Up / Sign In</a>
                                    </span>
                                    ]
                            }}
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
